import { Component, effect, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { TextFieldComponent } from '../../components/text-field/text-field.component';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { LoadingService } from '../../services/loading.service';
import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { accessTokenDataModel } from '../../models/Token.data.model';
import { ButtonTemplateComponent } from '../../components/button-template/button-template.component';
import { AccountService } from '../../services/account.service';
import { AuthorizationService } from '../../services/authorization.service';
import { AccountSelectionComponent } from '../../modules/authentication/account-selection/account-selection.component';
import { Empresa } from '../../models/Account.data.model';
import { secUserAccount } from '../../interfaces/security';
import { IdleService } from '../../services/idle.service';
import { WebsocketService } from '../../services/websocket.service';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
  imports: [
    ReactiveFormsModule,
    TextFieldComponent,
    CommonModule,
    ButtonTemplateComponent,
    AccountSelectionComponent,
  ],
  providers: [],
})
export class LoginComponent implements OnInit {
  token = new accessTokenDataModel();
  routes = inject(Router);

  isSubmitted = false;
  currentLogo: string = '../../../assets/images/logo-sistema.png';

  showAccountSelection = false;
  accountList: Empresa[] = [];

  loginData: any;
  replyData: any;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly accountService: AccountService,
    private readonly builder: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly loadingService: LoadingService,
    private readonly authorizationService: AuthorizationService,
    private readonly idleService: IdleService,
    private readonly wsService: WebsocketService,
    private readonly utility: UtilitiesService,
  ) {

    // this.formLogin.valueChanges.subscribe((val)=>console.log(this.formLogin));
    effect(() => {

    });
    
  }

  platformId = inject(PLATFORM_ID);
  
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isServer() {
    return isPlatformServer(this.platformId);
  }

  
  ngOnInit(): void {

    this.loadingService.isLoading.subscribe(
      (value) => (this.isSubmitted = value)
    );

    if(this.isBrowser) {

      this.wsService.wsLoginDataObserver().subscribe((value) => {
        this.loginData = value;
        console.log('WS_Socket [ Login ] loginData:', this.loginData);
      });
  
      this.wsService.wsReplyDataObserver().subscribe((value) => {
        this.replyData = value;
        console.log('WS_Socket [ Login ] replyData:', this.replyData);
      });

      
      this.authService.userLogoff();
      this.wsService.performWSUserLogoff();
  
      this.formLogin.reset();
  
      const _logo = this.accountService.getAccountLogoFromLocalHistory();
      if (_logo) this.currentLogo = _logo;
    }

  }

  formLogin = this.builder.group({
    email_field: this.builder.control('', [
      Validators.required,
      Validators.email,
    ]),
    pass_field: this.builder.control('', Validators.required),
  });



  get fc() {
    return this.formLogin.controls;
  }

  async submitLogin() {
    console.log('submit:', this.formLogin);

    if (this.formLogin.invalid) return;

    await this.getLoginToken();
  }

  goHome() {
    // debugger;
    this.routes.navigateByUrl('Dashboard');
  }

  redirectTo(page: string) {
    this.routes.navigateByUrl(page);
  }

  onCancelSelection() {
    console.log('[ Login ] cancelar selecionar empresa');
    this.authService.userLogoff();
    this.wsService.performWSUserLogoff();

    this.routes.navigateByUrl('/');
    this.showAccountSelection = false;
  }

  onAccountSelection(account: Empresa) {
    this.processaEmpresaSelecionada(account);
  }

  async processaEmpresaSelecionada(account: Empresa) {
    // debugger;
    console.log('[ Login ] empresa selecionada:', account);

    if (account && account.empresa_id) {
      let podeSeguir = false;

      // debugger;

      if (this.userService.currentUser && this.userService.currentUser.userid) {
        //recupera as permissões desse usuário

        const _userProfile: secUserAccount = {
          empresa_fk: account.empresa_id,
          status: this.userService.currentUser.status,
          user_account_role: this.userService.currentUser.user_role,
          Empresa: account,
          User: this.userService.currentUser,
        };
        this.accountService.setCurrentEmpresa(account);
        this.accountService.setAccountToLocalHistory(account);
        this.authorizationService.setCurrentProfile(_userProfile);
        this.authorizationService.setUserProfileToLocalHistory(_userProfile);

        this.showAccountSelection = false;

        podeSeguir = true;
      }

      if (podeSeguir) {
        const { userid, user_role, username } = this.userService.currentUser;

        if (userid && user_role) {
          const permissoes = await this.authorizationService
            .getPermissoesUsuarioById(account.empresa_id, userid)
            .catch((error) => {
              console.log('Falha ao verificar permissões:', error);

              this.toastr.warning(
                'Não foi possível identificar as permissões do usuário!'
              );
              this.authService.userLogoff();
              this.wsService.performWSUserLogoff();
            });
          // console.log('[ Login ] permissoes:', permissoes);

          let _podeSeguir = false;

          if (permissoes && permissoes.length) {
            this.authorizationService.setCurrentUserPermission(permissoes);
            _podeSeguir = true;
          } else {
            if (user_role !== 'ADMIN') {
              this.toastr.error('Usuário sem permissão no sistema!');

              this.authService.userLogoff();
              this.wsService.performWSUserLogoff();

            } else {
              this.toastr.success(
                `Olá ${username}, você é um Admin e possui acesso completo no sistema!`
              );
              _podeSeguir = true;
            }
          }

          if (_podeSeguir) {
            this.authorizationService.setAuthorizationStatus(true);
            this.idleService.setIdleStatus('IN ACTIVITY');
            this.idleService.setIdleCheckingActivation(true);

            await this.authService.registerUserAccessLog(
              'LOGIN',
              userid,
              user_role === 'ADMIN' ? undefined : account.empresa_id!
            );

            this.authService.setSessionStatus('ativa');

            console.log(
              'WS_Socket - enviar mensagem após login com sucesso...'
            );

            if(this.wsService.connect()) {

              const _geoData = this.authService.getGeolocationData();

              this.wsService.performUserLogin({
                action: 'login',
                userid,
                username,
                user_role,
                empresa_logada: account.empresa_id,
                ip_address: _geoData?.ip || undefined,
                token: this.authService.getTokenFomLocalHistory().accessToken,
              });
            }


            console.log(
              'WS_Socket [ Login ] mensagem do servidor:',
              this.loginData
            );
            console.log(
              'WS_Socket [ Reply ] mensagem do servidor:',
              this.replyData
            );

            this.goHome();
          }
        }

      }
    }
  }

  async getLoginToken() {
    if (this.fc.email_field.value && this.fc.pass_field.value) {
      let _sucesso = true;

      // debugger;

      const _result = await this.authService
        .userLogin(this.fc.email_field.value, this.fc.pass_field.value)
        .catch((reason) => {
          this.toastr.error(reason);
          // if(reason.statusCode === 404) {
          // } else {
          //   this.toastr.error(
          //     `Falha durante login. ${reason.message || 'Tente novamente.'}`
          //   );
          // }
          this.authService.userLogoff();
          this.wsService.performWSUserLogoff();

          _sucesso = false;
        });

      if (_sucesso) {
        //salvar a lista de transações
        //1. lista corrida e;
        //2. lista com menu/sub-menu
        console.log('WS_Service - entrar no connect após login...');
        // this.wsService.connect();

        this.authorizationService.setCurrentLoginAreaToHistory('empresa');

        await this.authorizationService.loadAndSave_AllTransactions();

        const menuOption = await this.authorizationService
          .getTransactionsMenuRouteList()
          .catch((error) => {
            this.toastr.error(
              'Não foi possível iniciar o sistema. Por favor, feche seu navegador e tente novamente!'
            );
            this.authService.userLogoff();
            this.wsService.performWSUserLogoff();

            return;
          });

        if (menuOption && menuOption.length) {
          this.authorizationService.setCurrentTransactionMenuSideNavOptions(
            menuOption
          );
        }

        // if (typeof _result === 'boolean' && _result) {
        //   if (_result) {
        //     this.toastr.success(`Login realizado com sucesso!`);

        //     if (this.userService.currentUser.user_role === 'ADMIN') {
        //       //monta lista de empresas ativa
        //       this.accountList = await this.accountService.getAllAccounts(
        //         undefined,
        //         undefined,
        //         'ATIVO'
        //       );

        //       if (this.accountList && this.accountList.length) {
        //         this.showAccountSelection = true;
        //         return;
        //       } else {
        //         this.routes.navigateByUrl('Administração/Empresas');
        //       }
        //     } else {
        //       this.routes.navigateByUrl('Dashboard');
        //     }
        //   } else {
        //     this.toastr.warning(`Login não realizado! Tente novamente.`);
        //     this.authService.userLogoff();
        //   }
        // }

        if (_result && _result.length) {
          if (_result.length === 1 && _result[0].Empresa) {
            await this.processaEmpresaSelecionada(_result[0].Empresa);
            return;
          }

          this.accountList = [];

          _result.map((value) => {
            if (value.Empresa) this.accountList.push(value.Empresa);
          });
          this.showAccountSelection = true;
        }
      }
    }
  }
}
