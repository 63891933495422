
<div
  class="h-screen overflow-clip bg-right md:bg-top bg-no-repeat bg-cover"
  style="background-image: url('../../../assets/images/bg_login_empresa2.jpg')"
>
  <div
    class="relative pr-3 pl-3 m-auto flex items-center justify-center min-h-screen"
  >
    <div
      class="hover:scale-[101%] relative xlg:absolute align-middle mt-auto h-max w-max left-0 lg:w-[27%] xlg:w-1/4 flex flex-col m-auto lg:ml-52 xlg:m-6 hover:outline-2 hover:outline-topvix-secondary bg-topvix-bg border border-gray-200 rounded-lg shadow p-3 lg:p-6"
    >
      <img
        class="hover:scale-105 object-scale-down m-auto w-44"
        [src]="currentLogo"
        alt=""
      />

      <div class="text-md text-center pt-5 pb-1 font-medium text-gray-500">
        Bem-vindo à TOPVIX fidelidade
      </div>
      <div class="text-sm text-center pb-4 font-normal text-gray-500">
        Informe seu login e acesse como empresa
      </div>

      <form
        [formGroup]="formLogin"
        class="flex flex-col p-0"
      >

        <text-field
          [control]="fc.email_field"
          inputId="email_user"
          type="email"
          [inputMode]="'email'"
          class="bg-topvix-bg border-0 pb-4 px-0 w-full"
          label="Email"
          [required]="true"
          [readonly]="isSubmitted"
        ></text-field>

        <text-field
          [control]="fc.pass_field"
          inputId="pass_user"
          type="password"
          class="bg-topvix-bg border-0 pb-4 px-0"
          label="Senha"
          [required]="true"
          [readonly]="isSubmitted"
        ></text-field>

        <button-template
          type="button"
          (onClick)="submitLogin()"
          [disabled]="formLogin.invalid"
          [buttonStyle]="'filled'"
          label="Entrar"
          [isLoading]="isSubmitted"
          loadingCaption="Aguarde..."
          >Entrar</button-template
        >

        <div class="flex flex-col text-center mt-3">
          <a
            (click)="redirectTo('reset')"
            class="cursor-pointer pb-3 text-xs hover:text-sm text-topvix-button hover:text-topvix-btn_hover"
            >Esqueceu sua senha?</a
          >
          <div class="pb-3 text-sm font-medium text-topvix-button">
            Sem acesso?
            <a
              (click)="redirectTo('register')"
              class="cursor-pointer text-sm hover:font-medium text-topvix-button hover:text-topvix-btn_hover"
              >Criar conta</a
            >
          </div>
          <div class="inline-flex items-center justify-center w-full">
            <hr class="w-72 h-px my-3 bg-gray-200 border-0" />
            <span
              class="absolute px-3 text-sm font-normal text-gray-500 bg-topvix-bg -translate-x-1/2 left-1/2"
              >Precisa de ajuda?</span
            >
          </div>
          <div class="text-sm text-center pb-4 font-normal text-gray-500">
            Envie um e-mail para
            <span class="text-topvix-button"
              ><a href="mailto:suporte&#64;topvix.com.br"
                >suporte&#64;topvix.com.br</a
              ></span
            >
          </div>
        </div>
      </form>
      <div class="text-xs text-center text-gray-500">
        Desenvolvido por Topvix ©️ 2024
      </div>
    </div>
  </div>
</div>

<account-selection
  *ngIf="showAccountSelection"
  [accountList]="accountList"
  (onCancel)="onCancelSelection()"
  (onConfirm)="onAccountSelection($event)"
></account-selection>
