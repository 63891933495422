/* src/app/pages/login/login.component.css */
.bg-image {
  height: 100%;
  background-image: url("./media/bg_login_empresa2-PXM7MFAA.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
/*# sourceMappingURL=login.component-TI6MX6V4.css.map */
